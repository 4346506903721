import * as _ from "lodash"
import * as angular from "angular"

subjectEditCtrl = (
  $scope
  $rootScope
  $uibModal
  $translate
  $window
  $q
  $state
  $stateParams
  Restangular
  DemographicCategorySvc
  HouseholdSvc
  LocationSvc
  RecruitingCampaignSvc
  SubjectInteractionSvc
  SubjectSvc
  ngToast
  COUNTRIES
  languageHelper
  CompanySvc
  current_subject
  show_household
  currentUser
) ->
  "ngInject"


  MINOR_AGE = 18
  CompanySvc.one(currentUser.company_id).customGET("settings").then (res) ->
    MINOR_AGE = res.meta.defaults.recruiting.adult_age || 18

  data = {
    org_subject: null
    hidden: {}
  }

  promises = [
    SubjectSvc.one(current_subject.id).get()
    HouseholdSvc.all().customGET('household_for_subject', { subject_id: current_subject.id })
    Restangular.all('subject_validation_rules').getList()
  ]

  $q.all(promises).then (results) ->
    # vm.new_subject_form.$setDirty() if vm.new_subject_form
    data.org_subject = Restangular.copy(results[0])
    vm.subject = results[0]
    vm.household = Restangular.restangularizeElement(null, results[1], 'households')

    data.all_rules = results[2]
    buildRules()

    setFormStateFromRules()

    _.each Object.keys(languageHelper.languages), (k) ->
      lang =
        name: languageHelper.languages[k]
        code: "#{k}"
      vm.languages.push(lang)

    return

  buildRules = ->
    data.rules = _.select(data.all_rules, (rule) ->
      rule.target == (if isMinor() then 'minor' else 'adult')
    )
    vm.category_rules = _.select(data.rules, (r) -> r.rule_type == 'demographic_category_rule')
    vm.exclusive_category_selections = _.reduce(vm.category_rules, (obj, cat) ->
      obj[cat.demographic_category.id] = _.find(vm.subject.demographic_ids, (demo_id) -> _.any(cat.demographic_category.demographics, (d) -> d.id == demo_id))
      obj
    , {})

  submit = (form) ->
    vm.state.errors = {}
    vm.subject.household = vm.household if vm.subject.head_of_household
    vm.subject.locale = $translate.proposedLanguage()
    vm.subject.onboarding_completed_at = current_subject.onboarding_completed_at = moment() unless vm.subject.onboarding_completed_at
    vm.subject.demographic_ids = _.compact _.values(vm.exclusive_category_selections)
    saved = vm.subject.save()

    saved.then ((result) ->
      vm.subject = result
      setFormStateFromRules()
      _.extend(current_subject, result) # something about assigning vm.subject to this result causes double saves to malfunction
      form.$setPristine()
      if $stateParams.pass_through_sref
        $state.go($stateParams.pass_through_sref, _.extend($stateParams, {locale: vm.subject.preferred_language}))
      else
        $translate(['SUBJECT_SAVED_MSG']).then (t) ->
          ngToast.success content: t.SUBJECT_SAVED_MSG
          $state.go('profile.about', _.extend($stateParams, {locale: vm.subject.preferred_language}))
      return
    ), (response) ->
      vm.state.errors = response.data.messages
      return
    return

  cancel = (form) ->
    vm.state.errors = {}
    form.$setPristine()
    vm.subject = Restangular.copy(data.org_subject)
    $translate.use(current_subject.preferred_language)

  switchLang = (lang) ->
    $translate.use(lang)

  showFields = (fields) ->
    _.any(fields, (field) -> !data.hidden[field])

  dateOfBirthChanged = ->
    return if isMinor() && current_subject.impersonator == null # we don't want to change any rules if you're updating your own profile
    buildRules()
    # vm.subject.head_of_household = false if isMinor()
    setFormStateFromRules()

  isMinor = (subject = vm.subject) ->
    return false if !subject || !subject.date_of_birth
    moment(subject.date_of_birth, 'YYYY-MM-DD', true).isAfter(moment().subtract(MINOR_AGE, 'years'))

  showOptional = (field) ->
    if isMinor()
      return false if _.includes(['code', 'date_of_birth'], field)
    else
      return false if _.includes(['code', 'first_name', 'last_name'], field)
    !vm.read_only[field] && !_.any(data.rules, (rule) ->
      rule.selected_attribute == field && (rule.validation_type == 'required' || rule.validation_type == 'required_and_unique')
    )

  setFormStateFromRules = ->
    vm.read_only = {}

    read_only_rules = _.filter(data.rules, (rule) ->
      rule.visibility == 'read_only' || (
        rule.after_collection == 'then_read_only' && (
          rule.rule_type == 'subject_field_rule' &&
          vm.subject[rule.selected_attribute]
        ) || (
          rule.rule_type == 'demographic_category_rule' &&
          rule.after_collection == 'then_read_only' &&
          _.any(vm.subject.demographic_ids, (demo_id) -> _.any(rule.demographic_category.demographics, (d) -> d.id == demo_id))
        )
      )
    )
    _.each read_only_rules, (rule) ->
      if rule.rule_type == 'subject_field_rule'
        vm.read_only[rule.selected_attribute] = true
      else if rule.rule_type == 'demographic_category_rule'
        vm.read_only["category_#{rule.demographic_category.id}"] = true
      return

    data.hidden = {}
    hiding_rules = _.filter(data.rules, (rule) ->
      rule.visibility == 'hidden' || (
        rule.after_collection == 'then_hidden' && (
          rule.rule_type == 'subject_field_rule' &&
          vm.subject[rule.selected_attribute]
        ) || (
          rule.rule_type == 'demographic_category_rule' &&
          rule.after_collection == 'then_hidden' &&
          _.any(vm.subject.demographic_ids, (demo_id) -> _.any(rule.demographic_category.demographics, (d) -> d.id == demo_id))
        )
      )
    )
    _.each hiding_rules, (rule) ->
      if rule.rule_type == 'subject_field_rule'
        data.hidden[rule.selected_attribute] = true
      else if rule.rule_type == 'demographic_category_rule'
        data.hidden["category_#{rule.demographic_category.id}"] = true
      return

    return

  exit_check = $rootScope.$on('$stateChangeStart', (event, toState, toParams, fromState, fromParams) ->
    if vm.new_subject_form && vm.new_subject_form.$dirty
      event.preventDefault()
      $translate(['CANCEL_BTN', 'UNSAVED_CHANGES_LBL', 'NOT_SAVED_MSG', 'CONTINUE_WITHOUT_SAVING_BTN']).then (t) ->
        $uibModal.open(
          templateUrl: "rj_shared/dialog.html"
          controller: "dialogCtrl"
          resolve:
            settings: -> {
              title: t.UNSAVED_CHANGES_LBL,
              message: t.NOT_SAVED_MSG,
              style: "danger",
              ok_button: t.CONTINUE_WITHOUT_SAVING_BTN,
              cancel_button: t.CANCEL_BTN
            }
        ).result.then (result) ->
          if result
            vm.new_subject_form.$setPristine()
            $state.go(toState)
  )

  $scope.$on('$destroy', ->
    exit_check()
  )

  vm = {
    subject: null
    languages: []
    category_rules: []
    exclusive_category_selections: {}
    household: null
    interaction: null
    part_of_subject_interaction: $stateParams.part_of_subject_interaction == 'true'
    read_only: {}
    countries: COUNTRIES
    state:
      errors: {}

    show_household: show_household
    submit: submit
    cancel: cancel
    switchLang: switchLang
    showFields: showFields
    showOptional: showOptional
    dateOfBirthChanged: dateOfBirthChanged
  }
  $scope.vm = vm

export default subjectEditCtrl

