import urls from "./urls"
import Cookies from "js-cookie"

// Helper function to get cookie by name using js-cookie
const getCookieByName = function (name) {
  return Cookies.get(name) || null
}

// Cookie management object
const CookieManager = {
  setCookie: function (tokenName, tokenValue, options: any = {}) {
    // Set default options
    if (!options.expires || !(options.expires instanceof Date) || isNaN(options.expires)) {
      options.expires = 365 * 10 // 10 years
    }
    if (!options.domain) {
      options.domain = urls.cookieDomainUrl()
    }
    options.path = options.path || "/"

    // Use js-cookie to set the cookie
    return Cookies.set(tokenName, tokenValue, options)
  },

  getCookie: function (name) {
    if (name === "auth" && window.current_user?.auth_token) {
      return window.current_user.auth_token
    }
    return getCookieByName(name)
  },

  deleteCookie: function (name) {
    return Cookies.remove(name, { path: "/" })
  },
}

export default CookieManager
