import * as angular from "angular"

import './subject_forms.scss'

consumer_subjects_ui = angular.module('redjade.consumer_subjects.ui', [
])
  .controller('subjectEditCtrl', require("./subjectEdit.controller").default)
  .run(($templateCache) ->
    "ngInject"
    # HTML
    $templateCache.put('consumer_subjects/ui/subject_edit.html', require("./subject_edit.html"))
  )

export default consumer_subjects_ui
